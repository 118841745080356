/* COLORS */
$primary-blue-100: #1F1A33;
$primary-blue-80: #4C485C;
$primary-blue-60: #797684;
$primary-blue-40: #A5A3AD;
$primary-blue-20: #D2D1D6;

$primary-green-100: #49C5B1;
$primary-green-80: #6DD1C1;
$primary-green-60: #92DCD0;
$primary-green-40: #B6E8E0;
$primary-green-20: #DBF3EF;

$primary-red-100: #F9423A;
$primary-red-80: #FA6862;
$primary-red-60: #FB8E89;
$primary-red-40: #FDB3B0;
$primary-red-20: #FED9D8;

$background-white: #FAFAFC;

$background-gray: #E5E5E5;

$light-gray: rgb(197, 196, 196);

/* FONTS */
$text-lg: 1.125rem;
$line-lg: 1.75rem;

$text-xl: 1.25rem;
$line-xl: 1.75rem;

$text-2xl: 1.5rem;
$line-2xl: 2rem;

$text-3xl: 1.875rem;
$line-3xl: 2.25rem;

$text-4xl: 2.25rem;
$line-4xl: 2.5rem;

$text-5xl: 3rem;
$line-5xl: 1;

$text-6xl: 3.75rem;
$line-6xl: 1;

$text-7xl: 4.5rem;
$line-7xl: 1rem;

@-webkit-keyframes breathing {
    0% {
        -webkit-transform: scale(0.9);
        transform: scale(0.9);
    }

    25% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }

    60% {
        -webkit-transform: scale(0.9);
        transform: scale(0.9);
    }

    100% {
        -webkit-transform: scale(0.9);
        transform: scale(0.9);
    }
}

@keyframes breathing {
    0% {
        -webkit-transform: scale(0.9);
        -ms-transform: scale(0.9);
        transform: scale(0.9);
    }

    25% {
        -webkit-transform: scale(1);
        -ms-transform: scale(1);
        transform: scale(1);
    }

    60% {
        -webkit-transform: scale(0.9);
        -ms-transform: scale(0.9);
        transform: scale(0.9);
    }

    100% {
        -webkit-transform: scale(0.9);
        -ms-transform: scale(0.9);
        transform: scale(0.9);
    }
}

@keyframes fadeInAnimation {
    0% {
        opacity: 0;
        transform: translateY(20px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

body {
    overflow: visible;
    background: $background-white;
}

.lds-dual-ring {
    display: inline-block;
    width: 80px;
    height: 80px;

    &:after {
        content: " ";
        display: block;
        width: 64px;
        height: 64px;
        margin: 8px;
        border-radius: 50%;
        border: 6px solid $primary-blue-100;
        border-color: $primary-blue-100 transparent $primary-blue-100 transparent;
        animation: lds-dual-ring 1.2s linear infinite;
    }
}
@keyframes lds-dual-ring {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.download_svg {
    &:hover {
        svg {
            fill: white;
        }
    }
}

.navbar {
    #primary-navigation {
        opacity: 0;
        pointer-events: none;
        &.bg-primary-green {
            background: $primary-green-100;
        }
        &.bg-white {
            background: white;
        }
        &.bg-primary-blue {
            background: $primary-blue-100;
        }
        &.bg-primary-red {
            background: $primary-red-100;
        }
        &.active {
            opacity: 100%;
            pointer-events: all;
        }
        @media screen and (min-width: 768px) {
            opacity: 100%;
            pointer-events: all;
            background: inherit !important;
        }

        .item {
            a {
                position: relative;
                &::after {
                    content: '';

                    position: absolute;
                    width: 100%;
                    height: 2px;

                    left: 0;
                    bottom: 0.125rem;
                    
                    background-color: white;

                    visibility: hidden;
                    transform: scaleX(0);
                    transition: all ease-in-out 100ms;
                }
                &:hover {
                    &::after {
                        visibility: visible;
                        transform: scaleX(1);
                    }
                }
            }
        }
    }
}

#nav-icon {
    width: 1.7rem;
    height: 1.7rem;
    margin: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .5s ease-in-out;
    -moz-transition: .5s ease-in-out;
    -o-transition: .5s ease-in-out;
    transition: .5s ease-in-out;
    cursor: pointer;

    span {
        display: block;
        position: absolute;
        height: 3px;
        width: 100%;
        background: white;
        border-radius: 9px;
        opacity: 1;
        left: 0;
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
        -webkit-transition: .25s ease-in-out;
        -moz-transition: .25s ease-in-out;
        -o-transition: .25s ease-in-out;
        transition: .25s ease-in-out;

        &:nth-child(1) {
            top: 0px;
            -webkit-transform-origin: left center;
            -moz-transform-origin: left center;
            -o-transform-origin: left center;
            transform-origin: left center;
        }
          
        &:nth-child(2) {
            top: 10px;
            -webkit-transform-origin: left center;
            -moz-transform-origin: left center;
            -o-transform-origin: left center;
            transform-origin: left center;
        }
          
        &:nth-child(3) {
            top: 20px;
            -webkit-transform-origin: left center;
            -moz-transform-origin: left center;
            -o-transform-origin: left center;
            transform-origin: left center;
        }
    }
    &.open {
        span {
            &:nth-child(1) {
                -webkit-transform: rotate(45deg);
                -moz-transform: rotate(45deg);
                -o-transform: rotate(45deg);
                transform: rotate(45deg);
                top: -3px;
                left: 6px;
            }
              
            &:nth-child(2) {
                width: 0%;
                opacity: 0;
            }
              
            &:nth-child(3) {
                -webkit-transform: rotate(-45deg);
                -moz-transform: rotate(-45deg);
                -o-transform: rotate(-45deg);
                transform: rotate(-45deg);
                top: 16px;
                left: 6px;
            }
        }
    }
  }

.sr-only {
    clip: rect(1px, 1px, 1px, 1px);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    width: 1px;

    &:focus {
        clip: auto;
        height: auto;
        overflow: auto;
        position: absolute;
        width: auto;
    }
}

.hide-scrollbar {
    &::-webkit-scrollbar {
        display: none;
    }
    
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

.page-title {
    opacity: 0;
    transform: translateY(20px);
    animation: fadeInAnimation ease 0.8s;
    animation-delay: 200ms;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;

    &-highlighted {
        &::after {
            content: '';
            position: absolute;
            background: white;
            height: 0.3rem;
            width: 100%;

            bottom: -10px;
            right: 50%;
            transform: translateX(50%);

            @media screen and (min-width: 768px) {
                width: 200%;
            }
        }
    }
}

.page-subtitle {
    opacity: 0;
    animation: fadeInAnimation ease 0.8s;
    animation-delay: 300ms;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
}

.page-anchor {
    /* opacity: 0;
    animation: fadeInAnimation ease 0.8s;
    animation-delay: 400ms;
    animation-iteration-count: 1;
    animation-fill-mode: forwards; */
    
    margin: auto;
    left: 50%;
    transform: translateX(-50%) !important;
}

.page-cta {
    opacity: 0;
    animation: fadeInAnimation ease 0.8s;
    animation-delay: 400ms;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
}



.spinner {
    img {
        -webkit-animation: breathing 3s ease-out infinite normal;
        animation: breathing 3s ease-out infinite normal;
    }
}

.bg {
    &-primary-green {
        background: $primary-green-100;
        &-light {
            background: $primary-green-60;
        }
    }
    &-primary-red {
        background: $primary-red-100;
        &-light {
            background: $primary-red-60;
        }
    }
    &-primary-blue {
        background: $primary-blue-100;
        &-light {
            background: $primary-blue-60;
        }
    }
    &-primary-gray {
        background: #E4E4EB;
    }
    &-soft-gray {
        background: $background-gray;
    }
    &-white {
        background: white;
    }
}

.text {
    &-left {
        text-align: left;
    }
    &-center {
        text-align: center;
    }
    &-right {
        text-align: right;
    }
}

.text-primary {
    &-blue {
        color: $primary-blue-100;
    }
    &-green {
        color: $primary-green-100;
    }
    &-red {
        color: $primary-red-100;
    }
    &-white {
        color: white;
    }
}

.btn {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    width: fit-content;
    padding: .7rem 1.8rem;
    border-radius: .5rem;

    &-rounded {
        border-radius: 100vh;
    }

    &-lg {
        text-transform: uppercase;
    }

    &-filled {
        &-gray {
            color: $primary-blue-100;
            background: $background-gray;
            &:hover {
                background: $primary-blue-100;
                color: white;
            }
            &:active {
                background: rgba($background-gray, 1);
                color: $primary-blue-100;
            }
            &-outline {
                border: solid 1px white;
            }
        }
        &-blue {
            color: white;
            background: $primary-blue-100;
            &:hover {
                background: $primary-blue-80;
            }
            &:active {
                background: $primary-blue-40;
            }
            &-outline {
                border: solid 1px white;
            }
        }
        &-green {
            color: $primary-blue-100;
            background: $primary-green-100;
            &:hover {
                background: $primary-green-80;
            }
            &:active {
                background: $primary-green-40;
            }
        }
        &-green-light {
            color: $primary-blue-100;
            background: $primary-green-60;
            &:hover {
                background: $primary-green-80;
            }
            &:active {
                background: $primary-green-40;
            }
        }
        &-red {
            color: white;
            background: $primary-red-100;
            &:hover {
                background: $primary-red-80;
            }
            &:active {
                background: $primary-red-40;
            }
        }
        &-white {
            color: $primary-blue-100;
            background: $background-white;
            &:hover {
                color: $background-white;
                background: $primary-blue-100;
            }
            &:active {
                background: $background-white;
                color: $primary-blue-100;
            }
        }
    }

    &-ghost {
        &-blue {
            background: transparent;
            color: $primary-blue-100;
            border: solid 1px $primary-blue-100;
            &:hover {
                background: $primary-blue-100;
                color: white;
            }
            &:active {
                background: $primary-blue-80;
                color: white;
            }
        }
        &-white {
            background: transparent;
            color: white;
            border: solid 1px white;
            &:hover {
                background: rgba(#ffffff, 0.4);
            }
            &:active {
                background: rgba(#ffffff, 0.8);
            }
        }
        &-green {
            background: transparent;
            color: $primary-green-100;
            border: solid 1px $primary-green-100;
            &:hover {
                background: $primary-green-60;
                color: white;
            }
            &:active {
                background: $primary-green-100;
                color: $primary-blue-100;
            }
        }
        &-red {
            background: transparent;
            color: $primary-red-100;
            border: solid 1px $primary-red-100;
            &:hover {
                background: $primary-red-60;
            }
            &:active {
                background: $primary-red-100;
                color: white;
            }
        }
    }
}

.data-points {
    h3 {
        font-weight: 1000;
    }

    &-highlight {
        &::before, &::after {
            display: block;
            content: '';
            position: absolute;
            left: 0;
            right: 0;
            height: 2px;
            background: radial-gradient(circle, rgba(87,87,87,0.2945553221288515) 0%, rgba(62,62,62,0.10968137254901966) 50%, rgba(255,255,255,1) 90%);
        }
        &::before {
            top: 0;
        }
        &::after {
            bottom: 0;
        }
    }
}

.triple-module {
    color: black;
    h3 {
        color: black;
        font-size: $text-3xl;
        font-weight: 600;
    }
    a {
        color: $primary-green-100;
        text-decoration: none;
        &:hover {
            text-decoration: underline;
        }
    }
}

.header {
    &-content {
        &-full {
            &.not-highlighted {
                &::before {
                    content: "";
                    display: block;
                    background: white;
                    width: 6rem;
                    height: 0.4rem;
                    top: 0;
                    left: 0;
                }
            }
        }
    }
}

.header-image{
    pointer-events: none;
    object-position: top;
}

.image_resized{
    width: 100%;
    position: relative;
}

.data-block, .engage-us, .founders {
    h3 {
        font-weight: 700;
        font-size: $text-3xl;
        line-height: $line-3xl;
    }
    h4 {
        font-weight: 700;
        font-size: 1.7rem;
        line-height: $line-2xl;
    }
    p {
        font-weight: 500;
        font-size: $text-xl;
        line-height: $line-xl;
    }

    ul {
        list-style: disc;
    }

    @media screen and (min-width: 768px) {
        h3 {
            font-size: $text-5xl;
            line-height: $line-5xl;
        }
        h4 {
            font-weight: 600;
            font-size: 2rem;
            line-height: $text-4xl;
        }
        p {
            font-size: $text-2xl;
            line-height: $line-2xl;
        }
    }

    &-green {
        strong {
            color: $primary-blue-100;
        }
    }
    &-blue {
        strong {
            color: $primary-green-100;
        }
    }
    &-red {
        strong {
            color: $primary-blue-100;
        }
    }
    &-white {
        strong {
            color: $primary-green-100;
        }
    }
    &-gray {
        strong {
            color: $primary-red-100;
        }
    }
}

.founders {
    h3 {
        font-weight: 700;
        font-size: $text-3xl;
        line-height: $line-3xl;
    }
    p {
        font-weight: 500;
        font-size: $text-xl;
        line-height: $line-xl;
    }

    @media screen and (min-width: 768px) {
        h3 {
            font-size: $text-4xl;
            line-height: $line-5xl;
        }
        p {
            font-size: $text-2xl;
            line-height: $line-2xl;
        }
    }

    &-green {
        strong {
            color: $primary-blue-100;
        }
    }
    &-blue {
        strong {
            color: $primary-green-100;
        }
    }
    &-red {
        strong {
            color: $primary-blue-100;
        }
    }
    &-white {
        strong {
            color: $primary-green-100;
        }
    }
    .founder-image {
        img {
            object-position: 0 20%;
        }
    }
}


.contact-input {
    background: transparent;
    border: 0;
    border-bottom: solid 1px white;
    &:focus {
        outline: none;
    }
}

.image-grid {
    strong {
        color: $primary-green-100
    }
    .gradient {
        background: linear-gradient(360deg, #1F1A33 0%, rgba(39, 36, 69, 0.21) 30%, rgba(39, 36, 69, 0) 50%);
    }
}


.card-container {
    display: grid;
    grid-template-columns: repeat(1, minmax(225px, 1fr));
    @media screen and (min-width: 640px) {
        grid-template-columns: repeat(2, minmax(225px, 1fr));
    }
    @media screen and (min-width: 820px) {
        grid-template-columns: repeat(3, minmax(225px, 1fr));
    }
    @media screen and (min-width: 1024px) {
        grid-template-columns: repeat(4, minmax(225px, 1fr));
    }
    @media screen and (min-width: 1240px) {
        grid-template-columns: repeat(5, minmax(225px, 1fr));
    }
    grid-auto-rows: minmax(0, auto);
    grid-gap: 1.5rem;
    justify-content: center;

    &-articles {
        grid-template-columns: repeat(1, minmax(250px, 1fr));
        @media screen and (min-width: 640px) {
            grid-template-columns: repeat(2, minmax(250px, 1fr));
        }
        @media screen and (min-width: 1024px) {
            grid-template-columns: repeat(3, minmax(250px, 1fr));
        }
        @media screen and (min-width: 1240px) {
            grid-template-columns: repeat(3, minmax(250px, 1fr));
        }
        @media screen and (min-width: 1440px) {
            grid-template-columns: repeat(3, minmax(250px, 1fr));
        }
    }
}

.featured-team {
    .members {
        .team-card {
            min-width: 300px
        }
    }
}

.team-card {
    cursor: pointer;
    div[role=img] {
        width: 100%;
        aspect-ratio: 530/742; // 530/742
        filter: grayscale(100%);
        &:hover {
            filter: grayscale(0);
        }
    }
}

.image-wrapper {
    position: relative;
    &-venture {
        width: 70%;
        height: 14rem;

        .image {
            width: 100%;
            height: 100%;
            position: relative !important;
            object-fit: cover;
        }
    }
}

.double-slide {
    .bg-image {
        opacity: 70%;
    }
}

.venture-features {
    .venture-card {
        width: 250px;
    }

    .swiper {
        overflow-y: visible;
    }

    .swiper-slide {
        display: flex;
        justify-content: center;
        overflow-y: visible;
    }
}

.venture-card {
    .venture-logo {
        width: 50%;
        aspect-ratio: 1/1;
    }
    .featuredImage {
        filter: brightness(0.5)
    }
    &:hover {
        .card-body {
            background: $primary-green-100;
            color: white;
        }
    }
}

.venture-block {
    .content {
        h3 {
            font-weight: 700;
            font-size: $text-3xl;
            line-height: $line-3xl;
        }
        h4 {
            font-weight: 700;
            font-size: 1.7rem;
            line-height: $line-2xl;
        }
        ul {
            list-style: disc;
            padding-left: 2rem;
        }
        a {
            color: $primary-green-100;
            &:hover {
                text-decoration: underline;
            }
        }
        blockquote {
            padding: 0 0 0 0.5rem;
            font-size: $text-2xl;
            font-style: italic;
            font-weight: 600;
            
            @media screen and (min-width: 768px) {
                padding: 1rem 3rem;
            }

            border-left: solid 5px rgba(gray, 0.5);
            quotes: "“" "”" "‘" "’";
            &::before {
                content: open-quote;
            }
            &::after {
                content: close-quote;
            }

            p {
                display: inline;
            }
        }
    }
}

.milestone {
    &-item {
        .icon {
            filter: grayscale(100%);
            &-active {
                filter: grayscale(0);
            }
        }
    }

    .swiper {
        padding-top: 5rem;
    }
    
    .swiper-pagination {
        position: absolute;
        top: 0;
        width: 100%;
        display: flex;
        justify-content: space-around;
        align-items: center;
        height: fit-content;

        @media screen and (min-width: 768px) {
            padding: 0 11rem;
            justify-content: space-between;
        }

        &-bullet, &-bullet-active {
            height: fit-content;
            width: fit-content;
            padding-right: 1rem;
            background: inherit;
            color: $light-gray;
            font-size: 1.8rem;
            font-weight: 800;
            opacity: 1;
            scale: 1;
            transform: scale(1) !important;
        }
        &-bullet-active {
            color: $primary-green-100;
            display: inline;
        }
        &-bullet-active-next, &-bullet-active-next-next {
            transform: scale(1);
        }
    }
}

.article-card {
    .card {
        &-image {
            aspect-ratio: 1.8 / 1;
        }
    }

    &-horizontal {
        .article-summary {
            font-size: 1.3rem;
            p:not(:first-child) {
                display: none;
            }
            img, figure, h3, h2, ul, li, blockquote {
                display: none;
            }
        }
    }
    
    &-featured {
        grid-column: span 2;
        grid-row: span 2;
        .article-summary {
            font-size: 1.3rem;
            p:not(:first-child) {
                display: none;
            }
            img, figure, h3, h2, ul, li, blockquote {
                display: none;
            }
        }
    }
}

.team-bio {
    .team-image {
        width: 100%;
        aspect-ratio: 1 / 1.4;
    }
}

.article {
    .recents {
        position: sticky;
        top: 2.5rem;

        display: none;

        @media screen and (min-width: 768px) {
            display: block;
        }

        &-list {
            &::-webkit-scrollbar {
                width: 10px;
                &-track {
                    background-color: $background-gray;
                }
                &-thumb {
                    background-color: $primary-green-60;
                }
            }
        }
    }
    &-content {
        .image {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            align-self: center;
            figcaption {
                width: 100%;
                font-size: .8rem;
                color: rgba(gray, 0.8);
            }
        }
        ul {
            list-style: disc;
            padding-left: 2rem;
        }
        h2 {
            font-size: $text-4xl;
            font-weight: 600;
        }
        h3 {
            font-size: $text-3xl;
            font-weight: 600;
        }
        h4 {
            font-size: $text-2xl;
            font-weight: 600;
        }
        a {
            color: $primary-green-100;
            &:hover {
                text-decoration: underline;
            }
        }
        sup {
            top: -1em;
        }
        blockquote {
            font-size: $text-2xl;
            font-weight: 600;
            color: $primary-green-100 !important;
            
            padding: 1rem;
            border-left: solid 5px rgba(gray, 0.5);
            quotes: "“" "”" "‘" "’";
            &::before {
                content: open-quote;
            }
            &::after {
                content: close-quote;
            }
            
            @media screen and (min-width: 768px) {
                padding: 1rem 3rem;
            }

            p {
                display: inline;
                color: $primary-green-100 !important;
            }
        }
    }
}

.pagination-container {
    display: flex;
    list-style-type: none;
  
    .pagination-item {
      padding: 0 12px;
      height: 32px;
      text-align: center;
      margin: auto 4px;
      color: rgba(0, 0, 0, 0.87);
      display: flex;
      box-sizing: border-box;
      align-items: center;
      letter-spacing: 0.01071em;
      border-radius: 16px;
      line-height: 1.43;
      font-size: 13px;
      min-width: 32px;
  
      &.dots:hover {
        background-color: transparent;
        cursor: default;
      }
      &:hover {
        background-color: rgba(0, 0, 0, 0.04);
        cursor: pointer;
      }
  
      &.selected {
        background-color: rgba(0, 0, 0, 0.08);
      }
  
      .arrow {
        &::before {
          position: relative;
          /* top: 3pt; Uncomment this to lower the icons as requested in comments*/
          content: '';
          /* By using an em scale, the arrows will size with the font */
          display: inline-block;
          width: 0.4em;
          height: 0.4em;
          border-right: 0.12em solid rgba(0, 0, 0, 0.87);
          border-top: 0.12em solid rgba(0, 0, 0, 0.87);
        }
  
        &.left {
          transform: rotate(-135deg) translate(-50%);
        }
  
        &.right {
          transform: rotate(45deg);
        }
      }
  
      &.disabled {
        pointer-events: none;
  
        .arrow::before {
          border-right: 0.12em solid rgba(0, 0, 0, 0.43);
          border-top: 0.12em solid rgba(0, 0, 0, 0.43);
        }
  
        &:hover {
          background-color: transparent;
          cursor: default;
        }
      }
    }
  }

.article-picks {
    &-featured {
        border-bottom: solid 1px rgba(gray, 0.5);
        padding-bottom: 1.3rem;

        @media screen and (min-width: 768px) {
            border-bottom: none;
            padding-bottom: 0;
        }
    }
    &-row:not(:last-child) {
        border-bottom: solid 1px rgba(gray, 0.5);

        @media screen and (min-width: 768px) {
            border-bottom: none;
        }
    }
}

.latest-articles {
    .title {
        line-height: 6rem;
        @media screen and (min-width: 768px) {
            line-height: 10rem;
        }
        @media screen and (min-width: 1024px) {
            line-height: 13rem;
        }
        @media screen and (min-width: 1280px) {
            line-height: 16rem;
        }
    }
    .search {
        line-height: 16px;
        svg {
            width: 18.4px;
            height: 16px;
            @media screen and (min-width: 1024px) {
                width: 22.4px;
                height: 20px;
            }
        }
        input {
            width: 100%;
            @media screen and (min-width: 768px) {
                width: 0;
            }
        }
        &:hover {
            input {
                @media screen and (min-width: 768px) {
                    margin-left: 0.8rem;
                    width: 200px;
                }
                @media screen and (min-width: 768px) {
                    margin-left: 0.8rem;
                    width: 200px;
                }
                @media screen and (min-width: 768px) {
                    margin-left: 0.8rem;
                    width: 200px;
                }
            }
        }

        &-btn {
            padding: 10px 15px;
            border-radius: 100vh;
            background-color: inherit;

            &:hover {
                background-color: $primary-blue-100;
                svg {
                    fill: white
                }
            }
        }
    }
    .featured {
        
        padding-bottom: 1.25rem;
        border-bottom: solid 1px rgba(gray, 0.5);

        @media screen and (min-width: 768px) {
            padding-bottom: 0;
            border-bottom: none;
        }
    }
    .side {
        & > div:not(:last-child) {
            padding-bottom: 1.25rem;
            border-bottom: solid 1px rgba(gray, 0.5);
        }
    }
}

.featured-articles {
    &-latest {
        .article-list {
            display: grid;
            grid-template-columns: repeat(autofit, minmax(150px, 1fr));
            grid-auto-rows: minmax(0, auto);
            grid-gap: 1.5rem;
        }
        .article-card-featured {
            grid-column: span 1;
            grid-row: span 1;
        }
        @media screen and (min-width: 768px) {
            .article-list {
                display: grid;
                gap: 1.5rem;
                grid-template-columns: repeat(3, minmax(150px, 1fr));
            }
            .article-card-featured {
                grid-column: span 2;
                grid-row: span 2;
            }
        }
    }
    &-popular {
        .article-card {
            padding: 1.3rem 0;
            border-bottom: solid 1px rgba(gray, 0.5);
        }
    }
}


.single-job {
    &-main {
        &-info {
            color: #414B58;
            .descripton {
                h2 {
                    font-size: 1.4rem;
                }
                p {
                    font-size: 1.1rem;
                }
                ul {
                    list-style:disc;
                    padding-left: 2rem;
                }
            }
            ul {
                list-style:disc;
                padding-left: 2rem;
            }
            h2 {
                font-size: 1.4rem;
            }
        }
    }
}
.gh-jobs {
    .job-elenas {
        background: linear-gradient(90deg, rgba(75, 0, 255, 0) 0%, #4B00FF 100%);
        .location {
            color: white;
        }
    }
}

.data-form {
    .form-input {
        input {
            &:focus {
                outline: solid 1px $primary-blue-100;
            }
        }
    }
}

.tabs {
    &-title {
        button {
            display: flex;
            justify-content: center;
            align-items: center;

            border-radius: 7px 7px 0 0;

            border: solid 1px #dedfe1;
            overflow-y: hidden;

            @media screen and (max-width: 769px) {
                border: solid 1px #dedfe1;
                border-bottom: none;

                &:last-child {
                    border-bottom: solid 1px #dedfe1;
                }
            }

            &.active { 
                box-shadow: 0px 0px 9px 3px rgba(0,0,0,0.37);
                border-bottom: none;
            }
        }
    }
}

.narrative-module {
    &-content {
        .content {
            span {
                background-color: inherit !important;
            }
        }
    }
}

.accordion {
    &-item {
        &-content {
            ul {
                list-style-type: disc;
                padding-left: 2rem;
            }
        }
    }
}

.featured-link {
    .mask {
        background: linear-gradient(0deg, $primary-blue-100 0%, rgba($primary-blue-100, 0.75) 50%, rgba($primary-blue-100, 0.5) 100%);
        @media screen and (min-width: 768px) {
            background: linear-gradient(90deg, #1F1A33 0%, rgba(31, 26, 51, 0.75) 40%, rgba(31, 26, 51, 0) 100%);
        }
    }

    .meme {
        transition: all ease-in-out 1000ms;
        transition-delay: 250ms;
    }

    &:hover {
      .meme {
        transform: scale(1.05);
      }  
    }
}

.info-gallery {
    .title {
        strong {
            color: $primary-green-100;
        }
        h3 {
            font-size: clamp(3rem, 5vw, 5rem);
            text-align: center;
            line-height: clamp(3rem, 5vw, 5rem);
        }
        &[data-length='1'] {
            @media screen and (min-width: 768px) {
                display: flex;
                align-items: center;
            }
            h3 {
                font-size: clamp(3.5rem, 2vw, 7rem);
                line-height: clamp(3.5rem, 7vw, 7rem);
                text-align: center;
                @media screen and (min-width: 768px) {
                    text-align: left;
                }
            }
        }
    }
    .card {
        color: white;
        .content {
            line-height: clamp(2rem,2.5vw,3rem);
        }
        &[data-number="0"] {
            strong {
                color: $primary-red-100;
            }
        }
        &[data-number="1"] {
            strong {
                color: $primary-green-100;
            }
        }
    }
}
