@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  padding: 0;
  margin: 0;

  overflow-x: hidden;
  min-height: 100vh;
}

a {
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  border: 0;
}

.article-content p {

  margin-bottom: 1em;

}


/*  font settings */

/* #### Generated By: http://www.cufonfonts.com #### */

@font-face {
  font-family: 'Whitney';
  font-style: normal;
  font-weight: normal;
  src: local('Whitney Medium'), url('/fonts/whitneymedium.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'Whitney';
  font-style: normal;
  font-weight: bold;
  src: local('Whitney Semibold Regular'), url('/fonts/whitneysemibold.woff') format('woff');
  font-display: swap;
}

/* 
  @font-face {
  font-family: 'Whitney';
  font-style: normal;
  font-weight: normal;
  src: local('Whitney Medium Italic'), url('whitneymediumitalic.woff') format('woff');
  }
  


  

  @font-face {
  font-family: 'Whitney';
  font-style: normal;
  font-weight: normal;
  src: local('Whitney Semibold Regular'), url('whitneysemibold.woff') format('woff');
  }
  

  @font-face {
  font-family: 'Whitney';
  font-style: normal;
  font-weight: normal;
  src: local('Whitney Book Regular'), url('whitneybook.woff') format('woff');
  }
  

  @font-face {
  font-family: 'Whitney';
  font-style: normal;
  font-weight: normal;
  src: local('Whitney Light Regular'), url('whitneylight.woff') format('woff');
  }
  

  @font-face {
  font-family: 'Whitney';
  font-style: normal;
  font-weight: normal;
  src: local('Whitney Semibold Italic'), url('WhitneySemiboldItalic.woff') format('woff');
  }
  

  @font-face {
  font-family: 'Whitney';
  font-style: normal;
  font-weight: normal;
  src: local('Whitney Book Italic'), url('whitneybookitalic.woff') format('woff');
  }
  

  @font-face {
  font-family: 'Whitney';
  font-style: normal;
  font-weight: normal;
  src: local('Whitney Light Italic'), url('whitneylightitalic.woff') format('woff');
  } */